/* PORTAL FRONT */
body {
    font-family: 'Raleway', sans-serif;
}

.nav-pc {
    background-color: rgb(255, 255, 255);
}

.show-cursor {
    cursor: pointer;
}



.loan-type {
    opacity: 1;
    transform: translateY(0%) scale(1) translateZ(0px);
    background-color: #d0f2f6;
    width: 70%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.loan-type-change-loan-selected-titulo {
    background: #e37070;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    border-radius: 10px;
}

.loan-type-change-loan-selected-personal {
    background: #5fc291d1;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    border-radius: 10px;
}

.loan-type-font {
    font-size: 11px;
}

.hidden-input {
    display: none;
}

.test-modal {
    width: 220px;
}
.navbar-brand-left {
    margin-left: 150px;
}
.hidden {
    display: none;
}

.btn-small {
    width: 100px !important;
}

.text-number {
    margin-top: 10px;
    color: #626262;
    font-weight: 700;
}

.nav-number {
    color: #00BFFF;
    font-weight: 1000;
}

.nav-item {
    padding: 8px;
}

.nav-text {
    margin-top: 9px;
}

.nav-image {
    margin-top: 4px;
}

.nav-credit {
    width: 190px;
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
}

.text-nav {
    color: #626262;
}

.text-credit {
    color: #fff;
    font-weight: 700;
}

/* Navbar mobil */
.nav-credit-movil {
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
}

.navbar-toggler {
    border: none;
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
}

/* CONTENT */
.grey-background {
    background-color: #F7F7F7;
}

.sol1-tittle {
    color: #005593;
    font-weight: 700;
}

.white-tittle {
    color: #ffff;
    font-weight: 700;
}

.link-tittle {
    color: #005593;
    font-weight: 400;
}

.validate-btn {
    width: 40%;
}

.txt-blue-lig {
    color: #005593;
    font-weight: 200;
    font-size: 40px;
}

.txt-blue-strong {
    color: #005593;
    font-weight: 400;
    font-size: 20px;
}

.txt-blue-strong2 {
    color: #005593;
    font-weight: 700;
    font-size: 15px;
}

.text-contact {
    margin-left: 10px;
    margin-top: 10px;
}

.circle-step {
    width: 40px;
    height: 40px;
    border: none;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #D3D3D3;
}

.circle-step-blue {
    width: 40px;
    height: 40px;
    border: none;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: radial-gradient(circle at top left, #005593, #07C3FD);
}

.numbre-step {
    cursor: auto;
    margin-top: 5px;
    color: #FFFFFF;
    font-weight: 700;

}

.img-top {
    margin-top: 220px;
    margin-left: 30px;
}

.img-top-sol8 {
    margin-top: 635px;
    margin-left: 30px;
}


.img-top2 {
    margin-top: 87px;
}

.img-top2-sol8 {
    margin-top: 528px;
}


.line {
    margin-top: 20px;
    border-top: 2px dashed #cccccc;
    height: 1px;
    overflow: hidden;
}

.card-redounded {
    width: 40%;
    border-radius: 20px;
    border: none;
}

.card-redounded-sm {
    width: 80%;
    border-radius: 20px;
    border: none;
}

.card-redounded-lg {
    width: 90%;
    margin: auto auto;
    border-radius: 20px;
    border: none;
}

.text-strong-blue {
    font-weight: 700;
    color: #005593;
}

.text-light-blue {
    font-weight: 700;
    color: #41E6ED;
}

.circle-money1 {
    margin-top: 5px;
    width: 30px;
    height: 30px;
    border: none;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #005693;
    margin-right: 25%;
}

.circle-money2 {
    margin-top: 5px;
    width: 30px;
    height: 30px;
    border: none;
    -moz-border-radius: 80%;
    -webkit-border-radius: 80%;
    border-radius: 80%;
    background: #005693;
    margin-left: 25%;
}

.simbols-money {
    margin-top: 2px;
    
    margin-right: 100%;
    color: #FFFFFF;
    font-weight: 100;
}

.container-range {
    margin-top: 30px;
}

.form-range {
    width: 85%;
}

.image-range {
    width: 50%;
}

.mrirht {
    margin-right: 20px;
}

.mleft {
    margin-left: 20px;
}

.mrirht2 {
    margin-right: 10px;
}

.mleft2 {
    margin-left: 10px;
}

.cerd-son {
    width: 75%;
    border-radius: 20px;
}

.text-small {
    color: #646464;
    font-size: 10px;
    text-align: justify;
}

.text-check {
    color: #646464;
    font-size: 16px;
    text-align: justify;
}

.text-tittle {
    font-size: 70px;
    font-weight: 750;
}

.input-number {
    width: 40px;
}

.btn-shadow {
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 12px;
    width: 30%;
    font-size: 20px;
}
.btn-shadow-cancela {
    background: radial-gradient(circle at top left, #f05865, #930000);
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 12px;
    width: 30%;
    font-size: 20px;
}

.btn-continue {
    background: radial-gradient(circle at top left, #00BFFF, #41E6ED);
    border: none;
    color: #0062A0;
    font-weight: 700;
    border-radius: 12px;
    width: 30%;
    font-size: 20px;
}

.btn-shadow-2 {
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 12px;
    width: 30%;
    font-size: 20px;
}

.btn-shadow-3 {
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 12px;
    width: 15%;
    font-size: 20px;
}

.btn-continue-large {
    background: radial-gradient(circle at top left, #00BFFF, #41E6ED);
    border: none;
    color: #0062A0;
    font-weight: 700;
    border-radius: 12px;
    width: 40%;
    font-size: 20px;
}

.btn-file {
    background: radial-gradient(circle at top left, #008DCC, #005593);
    border: none;
    color: #0062A0;
    color: #fff;
    font-weight: 700;
    border-radius: 12px;
    font-size: 15px;
}


.btn-shadow:hover {
    color: #FFFFFF;
}

.btn-file:hover {
    color: #FFFFFF;
}

.btn-shadow-2:hover {
    color: #FFFFFF;
}

.btn-continue:hover {
    color: #0062A0;
}

.btn-border {
    border: solid #00BFFF 1px;
    padding: 7px;
}

.text-grey {
    color: #707070;
}

.text-blue-light {
    color: #41E6ED;
    font-weight: 800;
}


.slect-blue {
    background-image: url('../img/flecha_abajo.png');
    background-color: #008AC9;
}

.clearfix {
    *zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.marginb {
    margin-bottom: 20px;
}

.hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.input-block-level {
    display: block;
    width: 100%;
    min-height: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}

select option {
    color: rgb(0, 0, 0);
    background-color: #fff;
}

.btn-file>input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    font-size: 23px;
    direction: ltr;
    cursor: pointer;
}

.fileupload {
    margin-bottom: 9px;
}

.fileupload .uneditable-input {
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: middle;
    cursor: text;
}

.fileupload .thumbnail {
    overflow: hidden;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
    text-align: center;
}

.fileupload .thumbnail>img {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
}

.fileupload .btn {
    vertical-align: middle;
}

.fileupload-exists .fileupload-new,
.fileupload-new .fileupload-exists {
    display: none;
}

.fileupload-inline .fileupload-controls {
    display: inline;
}

.fileupload-new .input-append .btn-file {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.thumbnail-borderless .thumbnail {
    border: none;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.fileupload-new.thumbnail-borderless .thumbnail {
    border: 1px solid #ddd;
}

.control-group.warning .fileupload .uneditable-input {
    color: #a47e3c;
    border-color: #a47e3c;
}

.control-group.warning .fileupload .fileupload-preview {
    color: #a47e3c;
}

.control-group.warning .fileupload .thumbnail {
    border-color: #a47e3c;
}

.control-group.error .fileupload .uneditable-input {
    color: #b94a48;
    border-color: #b94a48;
}

.control-group.error .fileupload .fileupload-preview {
    color: #b94a48;
}

.control-group.error .fileupload .thumbnail {
    border-color: #b94a48;
}

.control-group.success .fileupload .uneditable-input {
    color: #468847;
    border-color: #468847;
}

.control-group.success .fileupload .fileupload-preview {
    color: #468847;
}

.control-group.success .fileupload .thumbnail {
    border-color: #468847;
}

.close {
    float: none;
    color: #005593;
    text-decoration: none;
}

/* FOOTER */
.footer-clients {
    background-color: #fff;
    width: 100%;
}

.footer-text {
    background-color: #F7F7F7;
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.footer {
    background: radial-gradient(circle at top left, #0084E9, #00AAE9);
}

.img-client {
    margin-right: 5%;
}

.card-monto {
    width: 70%;
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
}

.blue-background {
    background: radial-gradient(circle at top left, #0084E9, #00AAE9);
}

.blue-background-mess {
    background: radial-gradient(circle at top left, #005593, #00AAE9);
}

@media only screen and (min-width:320px) and (max-width: 479px) {
    .text-tittle {
        font-size: 45px;
        font-weight: 750;
    }

    .btn-continue {
        background: radial-gradient(circle at top left, #00BFFF, #41E6ED);
        border: none;
        color: #0062A0;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }

    .btn-shadow-2 {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }

    .btn-shadow {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }
        .btn-shadow-cancela {
        background: radial-gradient(circle at top left, #f05865, #930000);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }


    .input-number {
        width: 35px;
        height: 40px;
    }

    .text-credit {
        color: #fff;
        font-weight: 700;
        font-size: 13px;
    }

    .text-light-blue {
        font-weight: 700;
        color: #41E6ED;
        font-size: 18px;
    }


    .btn-shadow-3 {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 100%;
        font-size: 20px;
    }

}
@media only screen and (min-width:767px) and (max-width: 1189px) {
    .btn-shadow {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 50%;
        font-size: 20px;
    }

    .circle-money1 {
        margin-top: 5px;
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 50%;
        background: #005593;
        margin-right: 10%;
    }

    .card-monto {
        background: radial-gradient(circle at top left,#3fe5ed,#005593);
        width: 100%;
    }
}

@media only screen and (min-width:480px) and (max-width: 767px) {

    .btn-shadow-3 {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 100%;
        font-size: 20px;
    }

    .btn-shadow {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }
    .btn-shadow-cancela {
        background: radial-gradient(circle at top left, #f05865, #930000);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }
    .btn-continue {
        background: radial-gradient(circle at top left, #00BFFF, #41E6ED);
        border: none;
        color: #0062A0;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }

    .btn-shadow-2 {
        background: radial-gradient(circle at top left, #3FE5ED, #005593);
        border: none;
        color: #FFFFFF;
        font-weight: 700;
        border-radius: 12px;
        width: 70%;
        font-size: 20px;
    }

}

@media only screen and (min-width:768px) and (max-width: 1023px) {}

/* CUENTA ESTILOS */

.nav-cuenta {
    background: radial-gradient(circle at top left, #005593, #00AAE9);
}

.text-nav-cuenta {
    color: #ffffff;
}

.nav-text-cuenta {
    margin-top: 9px;
    margin-left: 25px;
}

.navbar-toggler-cuenta {
    border: none;
    background: none;
}

.card-slider {
    border: solid white 1px;
}

.bg-blue-slider {
    background: radial-gradient(circle at top left, #00AAE9, #005593);
    color: #fff;
    height: auto;
}

.card-monto-cuenta {
    background: radial-gradient(circle at top left, #3FE5ED, #005593);
    border: none;
    border-radius: 15px;
    margin-right: 20px;
}

.top-50 {
    margin-top: 8%;
}

.btn-border-dark {
    border: solid #656565 1px;
    color: black;
    padding: 7px;
}

.label-cuenta {
    color: #6A6A6A;
    font-size: 12px;
}

textbox {
    height: 80px
}

/* CSS PAOLA */

html, body {
    width: 100% !important;
    overflow-x: hidden;
}
.a_none_style{
    text-decoration: none;
}
@font-face {
    font-family: 'Open Sans Bold';
    src: url("fonts/OpenSans-Bold.ttf"); 
}
@font-face {
    font-family: 'Open Sans ExtraBold';
    src: url("fonts/OpenSans-ExtraBold.ttf"); 
}
@font-face {
    font-family: 'Open Sans Regular';
    src: url("fonts/OpenSans-Regular.ttf"); 
}
@font-face {
    font-family: 'Raleway Black';
    src: url("fonts/Raleway-Black.ttf"); 
}
@font-face {
    font-family: 'Raleway ExtraBold';
    src: url("fonts/Raleway-ExtraBold.ttf"); 
}
@font-face {
    font-family: 'Raleway Light';
    src: url("fonts/Raleway-Light.ttf"); 
}
@font-face {
    font-family: 'Raleway Regular';
    src: url("fonts/Raleway-Regular.ttf"); 
}
@font-face {
    font-family: 'Raleway Medium';
    src: url("fonts/Raleway-Medium.ttf"); 
}
@font-face {
    font-family: 'Raleway Bold';
    src: url("fonts/Raleway-Bold.ttf"); 
}
@font-face {
    font-family: 'Raleway SemiBold';
    src: url("fonts/Raleway-SemiBold.ttf"); 
}
.oBold{
    font-family: 'Open Sans Bold';
}
.oExtraBold{
    font-family: 'Open Sans ExtraBold';
}
.oRegular{
    font-family: 'Open Sans Regular';
}
.rBlack{
    font-family: 'Raleway Black';
}
.rExtraBold{
    font-family: 'Raleway ExtraBold';
}
.rLight{
    font-family: 'Raleway Light';
}
.rMedium{
    font-family: 'Raleway Medium';
}
.rRegular{
    font-family: 'Raleway Regular';
}
.rBold{
    font-family: 'Raleway Bold';
}
.rSemiBold{
    font-family: 'Raleway SemiBold';
}
/* BACKGROUNDS */
.bg-solicitar-cred{
    background: rgb(63,229,237);
    background: linear-gradient(165deg, rgba(63,229,237,1) 5%, rgba(0,85,147,1) 42%, rgba(0,85,147,1) 100%);
    color: #ffffff;
    width: 220px;
    text-align: center;
}
.bg-index{
    background-image: url('../img/bg-index.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 90vh;
    color: #ffffff;
}
.bg-azul-cirulo{
    background: rgb(0,191,255);
    background: linear-gradient(180deg, rgba(0,191,255,1) 0%, rgba(0,96,128,1) 37%, rgba(0,96,128,1) 100%);
    width: 35px;
    height: 35px;
    border-radius: 100%;
    float: left;
    margin-left: 3rem;
}
.bg-cuadro-bco{
    background-color: #ffffff;
    border-radius: 35px;
    margin-top: 5rem;
}
.bg-cards{
    background-color: #FAFAFA;
    border-radius: 25px;
    text-align: center;
}
.bg-familia-index{
    background-image: url('../img/bg-fam.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
}
.bg-logo{
    background-image: url('../img/bg-percapita.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-cuadro-1{
    background: rgb(0,156,219);
    background: linear-gradient(180deg, rgba(0,156,219,1) 13%, rgba(0,85,147,1) 53%, rgba(0,85,147,1) 100%);
    width: 110px;
    height: 110px;
    border-radius: 15px;
    float: left;
    margin-right: 1.5rem;
}
.bg-cuadro-2{
    background: rgb(63,229,237);
    background: linear-gradient(180deg, rgba(63,229,237,1) 2%, rgba(0,85,147,1) 39%, rgba(0,85,147,1) 100%);
    width: 110px;
    height: 110px;
    border-radius: 15px;
    float: left;
    margin-right: 1.5rem;
}
.bg-pabs{
    background-image: url('../img/bg-pabs.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 65vh;
}
.bg-cuadro-azul{
    background: rgb(0,170,233);
    background: linear-gradient(168deg, rgba(0,170,233,1) 2%, rgba(0,85,147,1) 64%, rgba(0,85,147,1) 100%);
    color: #ffffff;
    border-radius: 45px;
    margin-top: 4rem;
    text-align: center;
}
.bg-gris{
    background-color: #FAFAFA;
}
.bg-footer{
    background-image: url('../img/bg-footer.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-index-phone{
    background-image: url('../img/bg-index-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
}
.bg-familia-index-phone{
    background-image: url('../img/bg-fam-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
}
.bg-pabs-phone{
    background-image: url('../img/bg-pabs-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-nosotros{
    background-image: url('../img/bg-nosotros.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 80vh;
}
.bg-familia-nosotros{
    background-image: url('../img/bg-familia-nosotros.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 70vh;
}
.bg-nosotros-phone{
    background-image: url('../img/bg-nosotros-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    width: 100%;
}
.bg-familia-nosotros-phone{
    background-image: url('../img/bg-familia-nosotros-phone.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 45vh;
}
.bg-reviews{
    background-image: url('../img/bg-reviews.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
}
.bg-financiamiento{
    background-image: url('../img/bg-financiamiento.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 80vh;
}
.bg-solarum{
    background-image: url('../img/bg-solarum.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 80vh;
}
.bg-dire{
    background-image: url('../img/bg-dire.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 80vh;
}
.bg-contacto{
    background-image: url('../img/bg-contacto.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    height: 40vh;
}
.bg-blog{
    background-image: url('../img/bg-footer.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 30vh;
    color: #ffffff;
}
/* TERMINAN BACKGROUNDS */
/* COLOR TEXTOS */
.txt-azul{
    color: #00BFFF;
}
.txt-gris{
    color: #5A5A5A;
}
.txt-azul-marino{
    color: #005593;
}
.txt-azul2{
    color: #0071AF;
}
.txt-blanco {
    color: #FFFFFF;
}
/* TERMINA COLOR DE TEXTOS */
/* TEXTOS */
.txt-index{
    margin-top: 10rem;
}
.txt-index h1{
    line-height: 60%;
}
.txt-paso{
    font-size: 14px;
}
.bg-cards p{
    line-height: 40%;
    font-size: 12px;
}
.txt-libertad{
    margin-top: 7rem;
}
.txt-libertad h1{
    line-height: 90%;
    font-size: 2.8rem;
}
.txt-beneficios{
    margin-top: 2.5rem;
}
.txt-beneficios p{
    line-height: 50%;
    font-size: 13px;
}
.bg-cuadro-azul h3{
    line-height: 80%;
}
.txt-ayuda h1, h4{
    line-height: 80%;
}
.txt-final{
    font-size: 10px;
    color: #A8A8A8;
}
.contacto-footer h6{
    font-size: 15.3px;
}
.menu-footer h6{
    font-size: 15.3px;
}
.legal h6{
    font-size: 15.3px;
}
.txt-derechos{
    font-size: 14px;
    color: #ffffff;
}
.txt-fam-nosotros{
    margin-top: 25rem;
}
.txt-ayuda2{
    margin-top: 5rem;
}
.txt-financiamiento{
    margin-top: 18rem;
}
.txt-ayuda3 h1{
    line-height: 60%;
}
.txt-gracias{
    margin-left: 17rem;
}
.txt-solarum{
    text-align: center;
    margin-top: 17rem;
}
.txt-dire{
    margin-top: 5rem;
}
.txt-contacto{
    margin-top: 10rem;
}
/* TERMINAN TEXTOS */
/* ICONOS E IMÁGENES */
.logo-header{
    margin-left: 7rem;
}
.bg-azul-cirulo img{
    width: 20px;
}
.icono-solicitud{
    width: 80px;
}
.icono-celular{
    width: 50px;
}
.icono-documentos{
    width: 70px;
}
.icono-prestamo{
    width: 80px;
}
.icono-facilidad{
    margin-top: 1.5rem;
    width: 70px;
}
.icono-respaldo{
    margin-top: 1.5rem;
    width: 60px;
}
.logo-pabs{
    width: 150px;
}
.logo-gde{
    width: 350px;
}
.icono-condusef{
    width: 130px;
}
.icono-sat{
    width: 80px;
}
.icono-asofom{
    width: 130px;
}
.icono-buro{
    width: 90px;
}
.icono-buro2{
    width: 80px;
}
.icono-contacto{
    float: left;
    margin-right: 1rem;
}
.fa-bars{
    color: #ffffff;
}
.logo-credito{
    width: 300px;
    margin-top: 5rem;
}
.icon-percapita-nosotro{
    width: 50px;
}
.logo-fam-nosotros{
    width: 150px;
}
.icono-per-review{
    width: 40px;
}
.logo-dire{
    width: 400px;
}
.icono-headers{
    width: 70px;
}
.icon-contact{
    float: left;
    width: 50px;
    margin-right: 1rem;
}
/* TERMINAN ICONOS E IMÁGENES */
/* BOTONES */
.btn-minus{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #005593;
    border-color: #005593;
    font-size: 1.3rem;
    text-align: center;
    align-items: center;
}
.btn-plus{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #005593;
    border-color: #005593;
    font-size: 1.3rem;
    text-align: center;
    align-items: center;
}
.slidecontainer {
    width: 100%; /* Width of the outside container */
  }
 /* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 10px;
    border-radius: 5px;  
    background: #00BFFF; /* Grey background */
    outline: none; /* Remove outline */
}  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 66px;
    height: 66px;
    border: 100%;
    background: url('../img/icono-range.png');
    background-repeat: no-repeat;
    cursor: pointer;
    border:none;
    box-shadow: none;
}  
.slider::-moz-range-thumb {
    width: 80px;
    height: 66px;
    background: url('../img/icono-range.png');
    background-repeat: no-repeat;
    border:none;
    cursor: pointer;
}


.btn-solicitar{
    background: rgb(63,229,237);
    background: linear-gradient(141deg, rgba(63,229,237,1) 0%, rgba(0,85,147,1) 42%, rgba(0,85,147,1) 100%);
    border: none;
    border-radius: 10px;
    width: 200px;
}
.btn-conoce-azul{
    background: rgb(0,191,255);
    background: linear-gradient(153deg, rgba(0,191,255,1) 13%, rgba(65,230,237,1) 53%, rgba(65,230,237,1) 100%);
    border: none;
    border-radius: 10px;
    width: 200px;
}
.btn-conoce-azul-xl{
    background: rgb(0,191,255);
    background: linear-gradient(153deg, rgba(0,191,255,1) 13%, rgba(65,230,237,1) 53%, rgba(65,230,237,1) 100%);
    border: none;
    border-radius: 10px;
    width: 350px;
}
.btn-menu{
    background: rgb(63,229,237);
    background: linear-gradient(141deg, rgba(63,229,237,1) 0%, rgba(0,85,147,1) 42%, rgba(0,85,147,1) 100%);
    border: none;
    border-radius: 0;
    color: #ffffff;
}
/* TERMINAN BOTONES */
/* CARROUSEL DE OPINIIONES */
.glider {
    overflow: hidden;
}
.glider-2 {
    overflow: hidden;
}
.glider-contain {
    width: 1200px;
}
.glider div {
    margin: 5px;
  }
.glider-contain .glider-next, .glider-contain .glider-prev {
    font-size: 1.5rem;
    background: transparent;
    color: #ffffff;
    margin-top: 1rem;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
}
.glider-next-2{
    font-size: 1.5rem;
    background: transparent;
    color: #005997;
    border-radius: 100%;
    border: none;
}
.glider-prev-2{
    font-size: 1.5rem;
    background: transparent;
    color: #005997;
    border: none;
}
.card-review{
    background-color: #ffffff;
    border-radius: 35px;
}
.btn-glider-phone{
    background-color: #ffffff;
}
/* VERSIÓN MÓVIL */
.feedback-slider-item {
	position: relative;
    color: #676767;
    text-align: start;
}
.customer-feedback .owl-item .customer-img {
    margin-top: 1rem;
	width: 125px;
	height: 125px;
    margin-right: 2rem;
}
.customer-name {
	margin-top: 15px;
	margin-bottom: 25px;
    color: #005593;
}
.customer-feedback .owl-item .icono-per-review{
    margin-top: 1rem;
	width: 50px;
	height: 50px;
    margin-left: 4rem;
}
.feedback-slider-item p {
    font-size: 18px;
}
/* GREY BACKGROUND COLOR OF THE ACTIVE SLIDER */
.thumb-prev,
.thumb-next {
	position: absolute;
	z-index: 99;
	top: 0;
	width: 98px;
	height: 98px;
	left: -90px;
	cursor: pointer;
	transition: all 0.3s;
}
.thumb-next {
	left: auto;
	right: -90px;
}
.feedback-slider-thumb img {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.feedback-slider-thumb:hover {
	opacity: 0.8;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}
.customer-feedback .owl-nav [class*="owl-"] {
	position: relative;
	display: inline-block;
	bottom: 45px;
	transition: all 0.2s ease-in;
}
.customer-feedback .owl-nav i {
	background-color: transparent;
	color: rgb(0, 89, 151);
	font-size: 25px;
}
.customer-feedback .owl-prev {
	left: -15px;
}
.customer-feedback .owl-prev:hover {
	left: -20px;
}
.customer-feedback .owl-next {
	right: -15px;
}
.customer-feedback .owl-next:hover {
	right: -20px;
}
/* DOTS */
.customer-feedback .owl-dots {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 35px;
}
.customer-feedback .owl-dot {
	display: inline-block;
}
.customer-feedback .owl-dots .owl-dot span {
	width: 11px;
	height: 11px;
	margin: 0 5px;
	background: #fff;	
	display: block;
	-webkit-backface-visibility: visible;
	transition: all 200ms ease;
	border-radius: 50%;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
    margin-top: 3rem;
}
/* TERMINA CARROUSEL DE OPINIONES */
.pago-aprox{
    border: 2px solid #E5E5E5;
    border-radius: 35px;
}
.pago-aprox p{
    font-size: 8px;
    line-height: 10%;
}
.margen-cuadro{
    margin-left: 4rem;
    margin-right: 4rem;
}
.hr-footer{
    border: 1px solid #ffffff;
}
.pago-aprox{
    display: flex;
}
.pago-select{
    display: flex;
}
/* FORMULARIO */
.form-contacto input{
    font-family: 'Open Sans Regular';
    border-radius: 10px;
    border: none;
    color: #6A6A6A;
}
.form-contacto textarea{
    font-family: 'Open Sans Regular';
    border-radius: 10px;
    border: none;
    color: #6A6A6A;
}
.form-contacto label{
    font-family: 'Open Sans Regular';
    color: #6A6A6A;
}
.form-select{
    background: rgb(63,229,237);
    background-image: url("../img/flechita-drop.png");
    background-repeat: no-repeat;
    background-position: right 15% bottom 10px;
    background-size: 16px 12px;
    color: #ffffff;
}
/* TERMINA FORMULARIO */
/* ESTILOS DE CARDS DE BLOG */
.entrada-blog{
    position: relative;
}
.cuadro-blog{
    position: absolute;
    top: 10px;
    text-align: center;
    width: 150px;
    background: rgb(63,229,237);
    background: linear-gradient(141deg, rgba(63,229,237,1) 0%, rgba(0,85,147,1) 42%, rgba(0,85,147,1) 100%);
    color: #ffffff;
}
.fecha-pub img{
    width: 15px;
    margin-right: 2px;
}
.pagination{
    font-family: 'Open Sans Regular';
    padding-left: 0.5rem!important;
}
.page-item .page-link{
    border-radius: 100%;
    margin-left: 0.5rem!important;
    background-color: #F7F7F7;
    border: none;
    color: #919191;
}
.page-item.active .page-link{
    background-color: #05619A;
}
.page-item:first-child .page-link{
    border-radius: 100%;
    width: 35px;
    height: 35px;
}
.page-item:last-child .page-link{
    border-radius: 100%;
    width: 35px;
    height: 35px;
}
.modal-content{
    background: radial-gradient(circle at top left, #005593, #36c9d0);
}
/* TERMINAN LOS ESTILOS DE CARDS DE BLOG */
/* RESPONSIVO */ 
@media only screen and (min-width:320px) and (max-width: 479px){
    .margen-cuadro{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .bg-cuadro-bco{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-top: 2rem;
    }
    .icono-solicitud{
        width: 110px;
    }
    .icono-celular{
        width: 70px;
    }
    .icono-documentos{
        width: 90px;
    }
    .icono-prestamo{
        width: 120px;
    }
    .bg-cards p{
        font-size: 16px;
        line-height: 115%;
    }
    .txt-libertad{
        margin-top: 0;
    }
    .txt-libertad h1{
        font-size: 35px;
    }
    .bg-cuadro-1{

        float: none;
    }
    .bg-cuadro-2{
        float: none;
    }
    .txt-beneficios{
        margin-top: 0.5rem;
    }
    .txt-beneficios p{
        line-height: 115%;
        font-size: 17px;
    }
    .logo-pabs{
        width: 200px;
    }
    .txt-ayuda h4{
        line-height: 80%;
    }
    .icono-sat{
        width: 50px;
    }
    .icono-asofom{
        width: 90px;
    }
    .icono-condusef{
        width: 90px;
    }
    .icono-buro{
        width: 50px;
    }
    .icono-buro2{
        width: 50px;
    }
    .icono-per-review{
        width: 50px;
    }
    .txt-gracias{
        margin-left: 0;
    }
}
@media only screen and (min-width:480px) and (max-width: 577px){
    .bg-cards p{
        line-height: 115%;
    }
    .txt-beneficios p{
        line-height: 115%;
    }
    .bg-cuadro-1{
        margin-left: 3rem;
    }
    .bg-cuadro-2{
        margin-left: 3rem;
    }

}
@media only screen and (min-width:578px) and (max-width: 767px){
    .bg-cards p{
        line-height: 115%;
    }
}
@media only screen and (min-width:768px) and (max-width: 1023px){
    .txt-index{
        margin-top: 2rem;
    }
    .txt-index h1{
        line-height: 50%;
    }
    .bg-cuadro-bco{
        margin-top: 1rem;
    }
    .bg-index{
        height: auto;
    }
    .bg-cards p{
        line-height: 115%;
    }
    .bg-familia-index{
        height: auto;
    }
    .txt-beneficios p{
        line-height: 90%;
    }
    .bg-cuadro-1, .bg-cuadro-2{
        margin-bottom: 1rem;
    }
    .txt-ayuda h1{
        font-size: 20px;
    }
    .bg-pabs{
        height: auto;
    }
    .pago-aprox h2{
        font-size: 20px;
    }
    .pago-aprox p{
        font-size: 10px;
        line-height: 80%;
    }
    .pago-aprox{
        display: block;
    }
}
@media only screen and (min-width:1024px) and (max-width: 1200px){
    .txt-ayuda2 h4{
        font-size: 22px;
    }
    .txt-gracias{
        margin-left: 15rem;
    }
    .txt-ayuda3 h1{
        font-size: 30px;
    }
    .txt-direccion h5{
        font-size: 18px;
    }
    .bg-index{
        height: auto;
    }
    .bg-pabs{
        height: 120vh;
    }
    .pago-aprox h2{
        font-size: 20px;
    }
    .pago-aprox p{
        font-size: 10px;
        line-height: 82%;
    }
    .pago-aprox{
        display: block;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px){
    .bg-index{
        height: auto;
    }
    .bg-pabs{
        height: 120vh;
    }
}

@media only screen and (min-width: 1900px) and (max-width: 5000px){
    .bg-index{
        height: auto;
    }
}
@media only screen and (min-width: 1400px) and (max-width: 1900px){
    .bg-index{
        height: auto;
    }
    .bg-pabs{
        height: auto;
    }
    .bg-familia-nosotros{
        height: auto;
    }
    .txt-solarum{
        margin-top: 15rem;
    }
    .txt-index h1{
        line-height: 40%;
    }
    .txt-libertad h1{
        line-height: 60%;
    }
    .bg-nosotros h1{
        line-height: 40%;
    }
}

.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .float {
    position: fixed;
    /* width: 60px; */
    /* height: 85px; */
    bottom: 40px;
    margin-left: 10px;
    margin-bottom: 20px;
    /* right: 40px; */
     background-color: rgba(0, 204, 153, 0); 
    /* color: #FFF; */
    border-radius: 50px;
    text-align: center;
    /* box-shadow: 5px 5px 10px #99999900; */
    z-index: 100;
}
.blue-background-diremovil {
    background: radial-gradient(circle at top left, #110c75, #ac00e9);
}